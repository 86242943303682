// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accomodations-index-js": () => import("/opt/build/repo/src/pages/accomodations/index.js" /* webpackChunkName: "component---src-pages-accomodations-index-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-registry-index-js": () => import("/opt/build/repo/src/pages/registry/index.js" /* webpackChunkName: "component---src-pages-registry-index-js" */),
  "component---src-pages-rsvp-confirmation-js": () => import("/opt/build/repo/src/pages/rsvp/confirmation.js" /* webpackChunkName: "component---src-pages-rsvp-confirmation-js" */),
  "component---src-pages-rsvp-form-js": () => import("/opt/build/repo/src/pages/rsvp/form.js" /* webpackChunkName: "component---src-pages-rsvp-form-js" */),
  "component---src-pages-rsvp-index-js": () => import("/opt/build/repo/src/pages/rsvp/index.js" /* webpackChunkName: "component---src-pages-rsvp-index-js" */),
  "component---src-pages-wedding-index-js": () => import("/opt/build/repo/src/pages/wedding/index.js" /* webpackChunkName: "component---src-pages-wedding-index-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

